@use '~@angular/material' as mat;

@import '~themes/palettes';

@mixin g3p-overlay-error-dialog-theme($theme) {
    g3p-error-dialog {
        color: mat.get-color-from-palette($cgm-red-palette, 700);
        border-color: mat.get-color-from-palette($cgm-red-palette, 700);
    }
}

@use '../../../../../node_modules/@angular/material/index' as mat;

@import '../../../../themes/palettes';

@mixin g3p-container-theme($theme) {
    g3p-container {
        .g3p-app-container {
            background: linear-gradient(180deg, mat.get-color-from-palette($cgm-dark-blue-palette, 900) 11.25rem, mat.get-color-from-palette($cgm-dark-blue-palette, 50) 0%);
            &.collapsed-background {
                background: linear-gradient(180deg, mat.get-color-from-palette($cgm-dark-blue-palette, 900) 5.75rem, mat.get-color-from-palette($cgm-dark-blue-palette, 50) 0%);
            }

            .control-bar-container {
                color: mat.get-color-from-palette($cgm-neutral-palette, 50);
            }
            .container-left {
                border-left: 1px solid mat.get-color-from-palette($cgm-dark-blue-palette, 50);
                background: mat.get-color-from-palette($cgm-neutral-palette, 50);
            }
            .container, .container-right {
                .container-content {
                    background: mat.get-color-from-palette($cgm-neutral-palette, 50);
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
                }
            }
        }
    }
}

@use '~@angular/material' as mat;
@import '~@angular/material/theming';
@import '~themes/palettes';

@mixin g3p-filter-modal-theme($theme) {
    g3p-filter-modal {
        .mat-dialog-title {
            background: mat.get-color-from-palette($cgm-grey-palette, 200);
        }
    }
}

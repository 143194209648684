@import 'fonts';
@import 'forms';
@import 'table';

@import 'mixins';
@import 'animations';

// PADDINGS
$padding-020: 0.2rem;
$padding-025: 0.25rem;
$padding-030: 0.3rem;
$padding-040: 0.4rem;
$padding-050: 0.5rem;
$padding-058: 0.585rem;
$padding-060: 0.6rem;
$padding-062: 0.625rem;
$padding-070: 0.7rem;
$padding-075: 0.75rem;
$padding-080: 0.8rem;
$padding-100: 1rem;
$padding-1125: 1.125rem;
$padding-125: 1.25rem;
$padding-150: 1.5rem;
$padding-175: 1.75rem;
$padding-200: 2rem;
$padding-215: 2.15rem;
$padding-225: 2.25rem;
$padding-250: 2.5rem;
$padding-275: 2.75rem;
$padding-300: 3rem;
$padding-350: 3.5rem;
$padding-400: 4rem;
$padding-450: 4.5rem;
$padding-500: 5rem;
$padding-600: 6rem;
$padding-550: 5.5rem;
$padding-750: 7.5rem;

// APP FRAME
$g3p-header-height: 3rem;
$g3p-sidenav-width: 4rem;
$g3p-main-menu-width: 69.375rem;
$g3p-container-width: 100rem;
$g3p-control-bar-height: 6.5rem;
$g3p-user-menu-width: 13.25rem;
$g3p-tenant-selector-width: 40rem;
$g3p-context-overlay-normal-width: 31.25rem;
$g3p-context-overlay-expanded-width: calc(100% - 4.5rem);
$g3p-second-level-navigation-height: 3rem;

// ICON SIZES
$font-size-ico: 1.5rem;
$font-size-ico-small: 1.3rem;

// SUGGESTION LIST
$suggestion-list-height: 3.5rem;
$suggestion-list-small-height: 2.75rem;
$suggestion-list-input-width: 32rem;
$suggestion-list-big-medium-width: 30.25rem;
$suggestion-list-medium-width: 24.5rem;
$suggestion-list-small-medium-width: 20rem;
$suggestion-list-small-width: 16rem;
$suggestion-list-x-small-width: 12rem;

// MULTIPLE SELECTION
$multiple-selection-height-large: 3.5rem;

// DETAILS HEADER
$sidebar-header-height: 4.5rem;

// DIVIDER
$dividerLargeWidth: 68.75%;
$dividerSmallWidth: 31.25%;

// DIFFERENT SIZES FOR EXPANDED STATE OF PANELS
$containerPadding: 0.75rem;
$columnPadding: 1.75rem;

$leftColumn: 21rem;
$rightColumn: 29.5rem;

// ALERT MODAL
$alert-min-width: 35rem;

//INPUT
$input-height: 2.75rem;
$input-large-height: 3.5rem;
$input-medium-height: 2.75rem;

// checkboxes
$checkbox-width: 1.5rem;
$checkbox-height: 1.5rem;

@use '~@angular/material' as mat;
@import '~themes/palettes';

@mixin g3p-customer-details-theme($theme) {
    g3p-customer-details {
        .wrapper-user-customers {
            .col:nth-child(2) {
                color: mat.get-color-from-palette($cgm-red-palette, 900);
            }
        }
        .user-action-btns {
            display: flex;
            flex-direction: column;
            align-items: start;
        }
    }
}

@use '~@angular/material' as mat;

@import '~themes/common/values.scss';
@import '~themes/typography';

@mixin g3p-user-menu-mixin() {
    .mat-menu-panel {
        min-height: auto;
    }

    .user-name {
        @include font-14-regular();
        text-transform: initial;
    }

    .mat-menu-item {
        line-height: 1.25rem;
        text-transform: initial;
    }

    .mat-menu-panel {
        &.user-menu {
            border-radius: 0.25rem;
            width: $g3p-user-menu-width;
            padding: 0;
        }
    }
}

@mixin g3p-user-menu-theme($theme) {
    @include g3p-user-menu-mixin();

    .user-name {
        color: mat.get-color-from-palette($cgm-neutral-palette, 50);
    }
}

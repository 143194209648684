@font-face {
    font-family: 'Inter';
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    src: local('Inter'), local('Inter'), url('~assets/fonts/Inter.ttf');
}

@font-face {
    font-family: 'InterBold';
    font-display: swap;
    font-style: normal;
    font-weight: bold;
    src: local('InterBold'), local('InterBold'), url('~assets/fonts/Inter.ttf');
}

@font-face {
    font-family: 'InterSemiBold';
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    src: local('InterSemiBold'), local('InterSemiBold'), url('~assets/fonts/Inter.ttf');
}

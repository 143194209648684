@use '~@angular/material' as mat;
@import '~assets/fonts/font-family';

@import '@cgm/g3-component-lib/prebuilt-themes/g3-cgm-stella.theme';
@import '@cgm/cashbook-ui-lib/prebuilt-themes/cashbook.theme';

@import 'palettes';
@import 'common/values';
@import 'typography';

@import '~app/shared/components/header/header.theme';
@import 'src/app/shared/components/tss-status-popover/tss-status-popover.theme';
@import '~app/shared/components/dialog-header/dialog-header.theme';
@import '../app/shared/components/container/container.theme';
@import '../app/shared/components/filter-modal/filter-modal.theme';
@import '../app/shared/components/error-dialog/overlay-error-dialog.theme';
@import '~themes/partials/user-menu.theme';

@import '~app/workstation/registration/base-background/base-background.component.theme';
@import '~app/workstation/registration/registration.theme';
@import '~app/customer/customer.theme';
@import '~app/customer/components/customer-details/customer-details.theme';

$g3p-custom-typography: mat.define-typography-config(
    $font-family: $font-family-regular
);
@include mat.core($g3p-custom-typography);

$g3p-primary: mat.define-palette($cgm-blue-palette, 700, 400, 800);
$g3p-accent: mat.define-palette($cgm-green-palette, 500, 300, 700);

$g3p-alert: mat.define-palette($cgm-red-palette, 750, 700, 900);
$g3p-ok: mat.define-palette($cgm-green-palette, 500, 400, 600);
$g3p-warn: mat.define-palette($cgm-yellow-palette, 200, 100, 300);

$cashbook-standalone-theme: mat.define-light-theme($g3p-primary, $g3p-accent, $g3p-alert);

.cashbook-standalone-theme {
    font-family: $font-family-regular;
    @extend .g3-cgm-stella;
    @extend .cashbook-theme;

    @include mat.all-component-themes($cashbook-standalone-theme);

    @include g3p-container-theme($cashbook-standalone-theme);
    @include g3p-filter-modal-theme($cashbook-standalone-theme);
    @include g3p-header-theme($cashbook-standalone-theme);
    @include g3p-tss-status-popover-theme($cashbook-standalone-theme);
    @include g3p-user-menu-theme($cashbook-standalone-theme);
    @include g3p-base-background-theme($cashbook-standalone-theme);
    @include g3p-registration-theme($cashbook-standalone-theme);
    @include g3p-dialog-header-theme($cashbook-standalone-theme);
    @include g3p-customer-theme($cashbook-standalone-theme);
    @include g3p-customer-details-theme($cashbook-standalone-theme);
    @include g3p-overlay-error-dialog-theme($cashbook-standalone-theme)
}

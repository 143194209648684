@use '~@angular/material' as mat;

@import '~themes/palettes';

@mixin g3p-header-theme($theme) {
    g3p-header {
        .header-container {
            background-color: mat.get-color-from-palette($cgm-dark-blue-palette, 800);
            color: mat.get-color-from-palette($cgm-grey-palette, 50);

            &.white {
                background-color: mat.get-color-from-palette($cgm-grey-palette, 50);
                color: mat.get-color-from-palette($cgm-dark-blue-palette, 800);

                .logo-container {
                    color: mat.get-color-from-palette($cgm-dark-blue-palette, 800);

                    img {
                        filter: invert(87%) sepia(67%) saturate(1385%) hue-rotate(189deg) brightness(93%) contrast(94%);
                    }
                }
            }

            .status-active {
                background-color: mat.get-color-from-palette($cgm-green-palette, 800);
            }

            .status-not-active {
                background-color: mat.get-color-from-palette($cgm-red-palette, 900);
            }

            .logo-container {
                color: mat.get-color-from-palette($cgm-grey-palette, 50);
            }

            .user-menu-container {
                .user-menu-button {
                    color: mat.get-color-from-palette($cgm-grey-palette, 50);

                    &[aria-expanded] {
                        background-color: mat.get-color-from-palette($cgm-dark-blue-palette, 900);

                        .drop-down-button {
                            background-color: mat.get-color-from-palette($cgm-dark-blue-palette, 900);
                        }
                    }
                }
            }
            .divider-line {
                border-left: 1px solid mat.get-color-from-palette($cgm-dark-blue-palette, 600);
            }
        }

        .mat-menu-panel.user-menu {
            background-color: mat.get-color-from-palette($cgm-grey-palette, 50);

            .mat-menu-item {
                color: mat.get-color-from-palette($cgm-grey-palette, 900);
                font-weight: 300;
                text-align: left;
            }
        }
    }
}

$font-family-regular: 'Inter', sans-serif;
$font-family-bold: 'InterBold', sans-serif;
$font-family-semi-bold: 'InterSemiBold', sans-serif;
$font-family-regular-semi-bold: 'InterRegularSemiBold', sans-serif;

// SIZES
$font-size-10: 0.625rem;
$font-size-12: 0.75rem;
$font-size-13: 0.79rem;
$font-size-14: 0.875rem;
$font-size-16: 1rem;
$font-size-18: 1.125rem;
$font-size-20: 1.25rem;
$font-size-22: 1.375rem;
$font-size-24: 1.5rem;
$font-size-26: 1.625rem;
$font-size-28: 1.75rem;
$font-size-30: 1.875rem;
$font-size-32: 2rem;
$font-size-36: 2.25rem;
$font-size-48: 3rem;
$font-size-72: 4.5rem;

@use '~@angular/material' as mat;
@import '~themes/palettes';

@mixin g3p-customer-theme($theme) {
    g3p-customer {
        g3p-customer-details {
            g3-card {
                .container:not(:first-child) {
                    border-top: solid 1px mat.get-color-from-palette($cgm-grey-palette, 300);
                }
            }
        }
    }

    g3p-customer-create-modal {
        .forms-box{
            background: mat.get-color-from-palette($cgm-neutral-palette, 100);
            border: 1px solid mat.get-color-from-palette($cgm-neutral-palette, A200);
        }

        .title-line{
            box-shadow: inset 0px -1px 0px 0px mat.get-color-from-palette($cgm-neutral-palette, 300);

            &:last-child {
                box-shadow: none;
            }
        }
        .mat-icon.mdi-alert-circle {
            color: mat.get-color-from-palette($cgm-alert-palette, 200);
        }

        g3p-account-step {
            mat-expansion-panel {
                max-width: 36.5rem;
                margin: 1.5rem 0 !important;

                mat-expansion-panel-header {
                    height: 7rem !important;

                    .account-header {
                        margin: 0 0 0 3.75rem;
                        max-width: 25.5rem;
                    }

                    &.mat-expanded {
                        height: 7rem !important;
                    }

                    .header-content {
                        position: relative;

                        .mat-expansion-panel-header-title {
                            display: flex;
                        }

                        .delete-button {
                            position: relative;
                            left: 1.5rem;
                            margin-top: 0.25rem;
                        }

                        .header-input {
                            justify-content: unset;
                            margin-left: 3.5rem;

                            .mat-form-field {
                                height: 3.5rem;
                                line-height: 1.25rem;
                                margin-bottom: 0;
                                width: 25.5rem;
                                margin-right: 1.5rem;
                            }
                        }

                        .mdi-chevron-right {
                            position: absolute;
                            top: 1rem;
                            right: 1.5rem;
                        }
                    }
                }

                .account-container .field-container {
                    .input-half-width {
                        max-width: 16rem;
                        width: 16rem;
                    }
                }

                .suggestion-list-container {
                    mat-form-field {
                        width: 16rem !important;
                    }
                }

                .mat-expansion-panel-body {
                    padding: 0 1.5rem 0 !important;
                }
            }

            .delete-item {
                .mat-icon {
                    color: mat.get-color-from-palette($cgm-neutral-palette, 600);
                }
            }
            button.disabled {
                .mdi-delete {
                    opacity: 0.5;
                }
            }
            .deactivated {
                .mat-form-field-flex {
                    background-color: mat.get-color-from-palette($cgm-grey-palette, 100);
                    color: mat.get-color-from-palette($cgm-grey-palette, 500);
                }
            }
            .account-accordion-item-header {
                .mat-focus-indicator {
                    color: mat.get-color-from-palette($cgm-blue-palette, 700);
                }
                .mdi-delete {
                    color: mat.get-color-from-palette($cgm-neutral-palette, 600);
                }
            }
            .account-accordion-item {
                form.error {
                    border: 1px solid mat.get-color-from-palette($cgm-red-palette, 750);
                }
                &[aria-expanded="true"] {
                    .account-accordion-item-header:hover {
                        background-color: mat.get-color-from-palette($cgm-neutral-palette, A100);
                    }
                }
            }
            .account-accordion-item-body {
                .usage-item {
                    color: mat.get-color-from-palette($cgm-red-palette, 750);
                }
            }
        }

        g3p-workstation-step {
            .contact-card-cta--trash {
                .mat-icon {
                    color: mat.get-color-from-palette($cgm-neutral-palette, 600);
                }
                &.mat-button-disabled {
                    .mat-icon {
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    g3p-account-usage-modal {
        g3-modal-title,
        g3-modal-body > p {
            color: mat.get-color-from-palette($cgm-red-palette, 750);
        }
    }

    g3p-customer-list {
        g3-large-list {
            overflow: hidden;
            width: 100%;

            .filter-bar {
                width: auto;

                margin: {
                    left: $padding-080;
                    top: $padding-100;
                    right: 0;
                    bottom: $padding-100;
                }
            }

            .texts-top {
                width: 100%;

                .top-left {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .texts-bottom {
                .bottom-right {
                    flex: 1;
                }
            }
        }
    }
}

@use '~@angular/material' as mat;

@import '~themes/palettes';

@mixin g3p-registration-theme($theme) {
    g3p-registration {
        .registration-form-container {
            .registration-form {
                box-shadow: -15px 0px 100px 0px rgba(0, 0, 0, 0.4);
                background-color: mat.get-color-from-palette($cgm-neutral-palette, 50);

                .form-field {
                    .mat-form-field-appearance-fill {
                        .mat-form-field-flex {
                            border-radius: 0;
                            background-color: mat.get-color-from-palette($cgm-neutral-palette, 50);
                        }
                    }
                }

                .workstation-field {
                    border-bottom: 1px solid mat.get-color-from-palette($cgm-neutral-palette, 200);

                    .mdi-eye {
                        color: mat.get-color-from-palette($cgm-grey-palette, 400);
                    }
                }

                input:-webkit-autofill {
                    box-shadow: 0 0 0 2rem mat.get-color-from-palette($cgm-neutral-palette, 50) inset !important;
                }

                .login-problems {
                    color: mat.get-color-from-palette($cgm-blue-palette, 700);
                }

                .language-button {
                    color: mat.get-color-from-palette($cgm-neutral-palette, 50);
                }
            }
        }

        .pipeline-number {
            color: mat.get-color-from-palette($cgm-neutral-palette, 900);
        }
    }
}

@use '~@angular/material' as mat;

@import '~themes/palettes';

@mixin g3p-base-background-theme($theme) {
    g3p-base-background {
        .base-background-container {
            .header-section {
                background: linear-gradient(
                        180deg,
                        mat.get-color-from-palette($cgm-dark-blue-palette, 800) 40%,
                        mat.get-color-from-palette($cgm-dark-blue-palette, 850) 100%
                );
            }

            .content-section {
                background: linear-gradient(
                        180deg,
                        mat.get-color-from-palette($cgm-dark-blue-palette, 850) 0%,
                        mat.get-color-from-palette($cgm-dark-blue-palette, 900) 40%,
                        mat.get-color-from-palette($cgm-dark-blue-palette, 50) 0%
                );
            }

            .footer-section {
                background: mat.get-color-from-palette($cgm-dark-blue-palette, 50);
            }

            .header-container {
                color: mat.get-color-from-palette($cgm-neutral-palette, 50);
            }

            .welcome-message-container {
                color: mat.get-color-from-palette($cgm-neutral-palette, 50);

                .product-name {
                    color: mat.get-color-from-palette($cgm-neutral-palette, 50);
                }
            }
        }
    }
}

@import 'common/fonts';
@import 'palettes';
@import 'common/values';

// Regular and bold: FONT 12
@mixin font-12-regular() {
    font-family: $font-family-regular;
    font-size: $font-size-12;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1rem;
}

@mixin font-12-bold() {
    @include font-12-regular();
    font-family: $font-family-bold;
}

// Regular and bold: FONT 14
@mixin font-14-regular() {
    font-family: $font-family-regular;
    font-size: $font-size-14;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1.25rem;
}

@mixin font-14-semi-bold() {
    @include font-14-regular();
    font-family: $font-family-semi-bold;
    font-weight: 600;
}

@mixin font-14-bold() {
    @include font-14-regular();
    font-family: $font-family-bold;
    font-weight: bold;
}

@mixin font-14-regular-semi-bold() {
    @include font-14-regular();
    font-family: $font-family-regular-semi-bold;
    font-weight: 600;
}

@mixin font-16-regular-semi-bold() {
    @include font-16-regular();
    font-family: $font-family-regular-semi-bold;
    font-weight: normal;
}

@mixin font-18-regular-semi-bold() {
    font-family: $font-family-regular-semi-bold;
    font-size: $font-size-18;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1.5rem;
}

@mixin font-16-regular() {
    font-family: $font-family-regular;
    font-size: $font-size-16;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1.5rem;
}

@mixin font-16-semi-bold() {
    @include font-16-regular();
    font-family: $font-family-semi-bold;
    font-weight: 600;
}

@mixin font-16-bold() {
    @include font-16-regular();
    font-family: $font-family-bold;
    font-weight: bold;
}

// Regular and bold: FONT 18
@mixin font-18-regular() {
    font-family: $font-family-regular;
    font-size: $font-size-18;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1.5rem;
}

@mixin font-18-bold() {
    @include font-18-regular();
    font-family: $font-family-bold;
    font-weight: bold;
}

// Regular and bold: FONT 20
@mixin font-20-regular() {
    font-family: $font-family-regular;
    font-size: $font-size-20;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1.5rem;
}

@mixin font-20-bold() {
    @include font-20-regular();
    font-family: $font-family-bold;
    font-weight: bold;
}

// Regular and bold: FONT 22
@mixin font-22-regular() {
    font-family: $font-family-regular;
    font-size: $font-size-22;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1.75rem;
}

@mixin font-22-bold() {
    @include font-22-regular();
    font-family: $font-family-bold;
    font-weight: bold;
}

// Regular and bold: FONT 24
@mixin font-24-regular() {
    font-family: $font-family-regular;
    font-size: $font-size-24;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 2rem;
}

@mixin font-24-bold() {
    @include font-24-regular();
    font-family: $font-family-bold;
    font-weight: bold;
}

// Regular and bold: FONT 48
@mixin font-48-regular() {
    font-family: $font-family-regular;
    font-size: $font-size-48;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 2rem;
}

@mixin font-48-bold() {
    @include font-48-regular();
    font-family: $font-family-bold;
    font-weight: bold;
}

@use '~@angular/material' as mat;
@import '~@angular/material/theming';
@import '~themes/palettes';

@mixin g3p-dialog-header-theme($theme) {
    g3p-dialog-header {
        .header {
            border-bottom: 1px solid mat.get-color-from-palette($cgm-neutral-palette, 200);
        }
    }
}

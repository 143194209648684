@use '~@angular/material' as mat;

@import '~themes/palettes';

@mixin g3p-tss-status-popover-theme($theme) {
    g3p-tss-status-popover {
        .status-active {
            background-color: mat.get-color-from-palette($cgm-green-palette, 800);
        }

        .status-not-active {
            background-color: mat.get-color-from-palette($cgm-red-palette, 900);
        }
    }
}
